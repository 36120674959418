import selectionCheckboxes from "./selectionCheckboxes";
import column from "./column";
import mainMenu from "./mainMenu";
import error from "./error";

export default {
  selectionCheckboxes,
  column,
  mainMenu,
  error
};
