export enum IPropertyColumn {
  Text = "Text",
  ComboBox = "ComboBox",
  TagInput = "TagInput",
  Date = "Date",
  Number = "Number",
  CheckBox = "CheckBox",
  CheckList = "Checklist",
  Image = "Image",
  Blob = "Blob",
  Polymorph = "Polymorph",
  Color = "Color"
}
