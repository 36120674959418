import React from "react";
import S from 'gui/Components/Sidebar/SidebarSectionDivider.module.scss';

export class SidebarSectionDivider extends React.Component {
  render() {
    return (
      <div
        className={S.root}
      />
    );
  }
}